<!-- The Room view of the Virtual-Showroom Website. -->
<!-- @author Yasien Kashef -->
<!-- @version 2.0.0 -->
<template>
  <div class="room">
    <!-- Overlay -->
    <div class="overlay">
      <nav>
        <img ref="home" class="icon" @click="routeTo('Home')" src="@/assets/room_view/home_icon.png" title="Übersicht"/>
        <img ref="living" class="icon" @click="routeTo('Living')" src="@/assets/room_view/living_icon.jpg" title="Wohnzimmer"/>
        <img ref="premium-satin" class="icon" @click="routeTo('Premium-Satin')" src="@/assets/room_view/premium-satin_icon.png" title="Premium-Satin"/>
        <img ref="jersey" class="icon" @click="routeTo('Jersey')" src="@/assets/room_view/jersey_icon.png" title="Jersey"/>
        <img ref="mako-satin" class="icon" @click="routeTo('Mako-Satin')" src="@/assets/room_view/mako-satin_icon.png" title="Mako-Satin"/>
        <img ref="impulse" class="icon" @click="routeTo('Impulse')" src="@/assets/room_view/impulse_icon.png" title="Impulse"/>
      </nav>
    </div>
    <!-- Rotate-Hint -->
    <div ref="rotateHint" class="rotate-hint">
      <img ref="rotateHintIcon" class="icon" src="@/assets/icons/rotate_hint.gif" alt="Rotate to Landscape">
      <span class="text">Für das beste Erlebnis drehe dein Gerät ins Querformat :) !</span>
    </div>
    <!-- Tour-Viewer -->
    <iframe :src="roomPath" frameborder="0"></iframe>
  </div>
</template>

<script>
/**
 * Room view of the Virtual-Showroom Website.
 * It handles the overlay logic, landscape rotation on mobile device and path to tour.
 * @author Yasien Kashef
 * @version 2.0.0
 */
import $ from "jquery";

export default {
  name: "Room",
  computed: {
    roomPath() {
      return "showroom/index.html?media="+this.$route.query.room;
    }
  },
  methods: {
    routeTo(room) {
      if(room === 'Home') {
        this.$router.push(room);
      } else {
        this.$router.push({
          path: "/Room",
          query: {
            room: room
          }
        })
      }
    },
    setBorder() {
      let icon = this.$refs[this.$route.query.room.toLowerCase()];
      icon.classList.add('icon-bordered')
    },
    /**
     * Show the rotate hint if the device is in portrait orientation.
     * @since 1.3.0
     */
    showHideRotateHint() {
      this.$refs.rotateHint.style.display = "none";
      if(window.orientation === 0) {
        this.$refs.rotateHint.style.display = "flex";
      }
    }
  },
  mounted() {
    this.setBorder()
    // Show/Hide rotation hint
    this.showHideRotateHint();
    // On each orientation change detect current orientation again, and show/hint rotation hint.
    $(window).on("orientationchange", (event) => {
      this.showHideRotateHint();
    });
  },
  beforeDestroy() {
    $(window).off("orientationchange");
  }
};
</script>

<!-- Notice "scoped" attribute to limit CSS to this component only -->
<!-- @version 2.0.0 -->
<style lang="scss" scoped>
.room {
  width: 100%;
  height: 100vh;
  background-color: rgba(231, 231, 231, 1);
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;

    * {
      pointer-events: auto;
    }

    nav {
      pointer-events: none;
      .icon {
        width: 60px;
        height: 60px;
        margin: 10px;
        cursor: pointer;
        transition: all 0.25s linear;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);

        &:hover {
          transform: scale(0.9);
        }
      }

      .icon-bordered {
        border: 3px solid white;
      }
    }
  }

  .rotate-hint {
    display: none;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 1);

    .icon {
      width: 50vh;
    }

    .text {
      text-align: center;
      font-size: 1.5rem;
    }
  }

    /* Landscape Phones */
  @media (hover: none) and (max-width: 900px) and (orientation: landscape) {
    .overlay {
      nav {
        .icon {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>
